import {Component, ViewChild, HostListener} from '@angular/core';
import {SharedApiService} from "../../../shared/services/api.service";
import {GoogleMap} from "@angular/google-maps";
import {MapService} from "../../../shared/services/map.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {QuestionModalComponent} from "./components/question-modal/question-modal.component";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {AuthService} from "../../../shared/services/auth.service";
import {RegistrationToast} from "../../../shared/models/registrationToast.model";
import {User} from "../../../shared/models/user.model";
import {Store} from "@ngrx/store";
import {AuthenticationState} from "../../../shared/store/auth/auth.model";
import {EnvService} from "../../../shared/services/env.service";
import {slugify} from "../../../shared/util/slugify";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @ViewChild(GoogleMap) map!: GoogleMap;

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        if (window.scrollY >= 640) {
            this.scrolled = true;
        }
    }

    public organisations = [];
    public mapOptions: google.maps.MapOptions;
    public markers = [];
    private isPartnerPage: boolean = false;
    public scrolled: boolean = false;
    public showCookieBar: boolean = true;
    public showToast: boolean = true;
    public user?: User;

    constructor(
            public apiService: SharedApiService,
            public mapService: MapService,
            public authService: AuthService,
            public store: Store<{ auth: AuthenticationState }>,
            public envService: EnvService,
            private modalService: NgbModal,
            private router: Router,
    ) {
        if (localStorage.getItem('cookiesConsent')) {
            this.showCookieBar = false;
        }

        if (localStorage.getItem('registrationToast')) {
            let registrationToast = JSON.parse(localStorage.getItem('registrationToast'));

            if (registrationToast.expiry > (new Date()).getTime()) {
                this.showToast = false;
            }
        }

        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
        });

        this.mapOptions = mapService.mapOptions;

        this.apiService.getParentOrganisations().subscribe(data => {
            this.organisations = data;

            for (let organisation of this.organisations) {
                for (let address of organisation['partnerAddresses']) {
                    if (address.longitude && address.latitude) {
                        this.markers.push({
                            position: {
                                lat: parseFloat(address.latitude),
                                lng: parseFloat(address.longitude)
                            },
                            title: organisation['name'],
                            options: {
                                animation: google.maps.Animation.DROP,
                                icon: {
                                    url: 'assets/images/map-marker.png',
                                    scaledSize: {
                                        width: 36,
                                        height: 28,
                                    }
                                },
                            }
                        })
                    }
                }
            }

            this.map.googleMap.fitBounds(
                    MapService.getBounds(this.markers)
            );
        });
    }

    public ngOnInit() {
        this.router.events.pipe(filter(navigationEnd => navigationEnd instanceof NavigationEnd)).subscribe((navigationEnd: any) => {
            this.isPartnerPage = navigationEnd.url.startsWith('/content/partner/');
        });
    }

    public openQuestionModal() {
        const modalRef = this.modalService.open(QuestionModalComponent, {size: 'lg', backdrop: 'static'});
        modalRef.componentInstance.isPartnerPage = this.isPartnerPage;
    }

    public closeToast() {
        let registrationToast: RegistrationToast = {
            expiry: (new Date()).getTime() + (30 * 86400000), // 30 days * 86400000 milliseconds per day
        };

        localStorage.setItem('registrationToast', JSON.stringify(registrationToast));

        this.showToast = false;
    }

    public acceptCookies(cookies: string): void {
        localStorage.setItem('cookiesConsent', cookies);

        this.showCookieBar = false;
    }

    public slug(name: string) {
        return slugify(name);
    }
}
